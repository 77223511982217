import copy from 'copy-text-to-clipboard'
import { useState, useCallback, useEffect } from 'react'

export function useCopyClipboard(timeout = 1500) {
  const [hasCopied, setHasCopied] = useState(false)

  const copyClipboard = useCallback((text: string) => {
    const didCopy = copy(text)
    setHasCopied(didCopy)
  }, [])

  useEffect(() => {
    if (hasCopied) {
      const id = setTimeout(() => {
        setHasCopied(false)
      }, timeout)

      return () => clearTimeout(id)
    }

    return
  }, [timeout, hasCopied])

  return [copyClipboard, hasCopied] as const
}
